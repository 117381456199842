import {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext
} from "react";
import { logEvent } from "firebase/analytics";
import { useLocation, useNavigate } from "react-router-dom"
import { isExpired } from 'react-jwt';

//Hooks
import { useAuth } from "../hooks";

//Actions
import { authAction, getDistributionLeadsCount } from "actions";

// Utils
import handlePageView from "./global-ctx-helpers/handlePageView";
import analytics from "init/firebaseAnalitycs"

export const GlobalContext = createContext();

const showIpaf = process.env.REACT_APP_SHOW_IPAF === "true"
const showOne21Event = false
export const GlobalProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate()

  const {
    loginAction,
    logoutAction,
    tokenValidation,
    accessToken
  } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true)
  const [isHamburgerActive, setIsHamburgerActive] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [listingLeadsCounter, setListingLeadsCounter] = useState(null)

  const userRole = useMemo(() => {
    return {
      isMb: profile?.role?.name === "Member Broker",
      isVmb: profile?.role?.name == "Vice Member Broker"
    }
  }, [profile])

  const showInvoice = useMemo(() => {
    return profile?.id !== 1196
  }, [profile])

  const showInvoiceAndEreporting = useMemo(() => {
    const arr = [37, 38]
    const roleId = profile?.role?.id;

    return !arr.includes(roleId)
  }, [profile])

  const firebaseAnalytic = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams)
  }

  const getDistributionLeads = async () => {
    try{
      const res = await getDistributionLeadsCount("?lead_satus=menunggu");
      setListingLeadsCounter(res?.data?.data?.total ?? 0)
    } catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    const getAuth = async () => {
      try {
        await authAction.getUserDetail().then(res => {
          setProfile(res?.data ?? null)
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    if (!!accessToken?.token) {
      if (isExpired(accessToken?.token)) {
        logoutAction(() => navigate("/login"))
      } else {
        getAuth()
      }
    } else {
      setLoading(false)
    }
  }, [accessToken])

  useEffect(() => {
    const eventParams = handlePageView(location.pathname)
    firebaseAnalytic("page_view", eventParams)
  }, [location])

  useEffect(() => {
    getDistributionLeads()
  }, [])

  if (loading) return null;
  return (
    <GlobalContext.Provider value={{
      loginAction,
      logoutAction,
      tokenValidation,
      accessToken,
      profile,
      isHamburgerActive,
      setIsHamburgerActive,
      showInvoice,
      userRole,
      showIpaf,
      showOne21Event,
      showLoading,
      setShowLoading,
      firebaseAnalytic,
      showInvoiceAndEreporting,
      listingLeadsCounter
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalCtx = () => {
  const ctx = useContext(GlobalContext);

  if (!ctx) {
    throw new Error("useGlobalCtx must be used within the GlobalProvider");
  }

  return ctx;
};
