import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "react-tooltip/dist/react-tooltip.css";

//Components
import { ModalConfirmationV1 } from "components";

//Context
import { GlobalContext } from "contexts";

//Styles
import Styles from "./navbar.module.scss";

//Assets
import C21Gold from "assets/c21-gold.png";

import { ReactComponent as C21Logo } from "assets/logo-c21-navbar.svg";

import { ReactComponent as DetailListingActive } from "assets/nav-detail-listing-active.svg";
import { ReactComponent as DetailListingInActive } from "assets/nav-detail-listing-inactive.svg";
import { ReactComponent as EreportingActive } from "assets/nav-ereporting-active.svg";
import { ReactComponent as EreportingInActive } from "assets/nav-ereporting-inactive.svg";
import { ReactComponent as BuatListingActive } from "assets/nav-buat-listing-active.svg";
import { ReactComponent as BuatListingInActive } from "assets/nav-buat-listing-inactive.svg";
import { ReactComponent as KeyMetricDashboardInActive } from "assets/nav-key-metric-inactive.svg";
import { ReactComponent as MarketingActive } from "assets/nav-marketing-active.svg";
import { ReactComponent as MarketingInActive } from "assets/nav-marketing-inactive.svg";
import { ReactComponent as InvoiceActive } from "assets/nav-invoice-active.svg";
import { ReactComponent as InvoiceInActive } from "assets/nav-invoice-inactive.svg";
import { ReactComponent as NewLogoutIcon } from "assets/new-singout-icon.svg";
import { ReactComponent as IpafActive } from "assets/ipaf-icon-active.svg";
import { ReactComponent as IpafInactive } from "assets/ipaf-icon-unactive.svg";
import { ReactComponent as One21Active } from "assets/on21-icon-active.svg";
import { ReactComponent as One21Inactive } from "assets/on21-icon-inactive.svg";
import { ReactComponent as ProgressTrackerInactive } from "assets/progress-tracker-inactive.svg";
import { ReactComponent as ProgressTrackerActive } from "assets/progress-tracker-active.svg";
import { ReactComponent as LeadsManagementActive } from "assets/leads-management-active.svg";
import { ReactComponent as LeadsManagementInactive } from "assets/leads-management-inactive.svg";
import LeadsdistributionActive from "assets/leads-distribution-active.png";
import LeadsdistributionInactive from "assets/leads-distribution-inactive.png";

import { ReactComponent as DoubleLeftArrow } from 'assets/double-left-arrow.svg'

import useOutsideAlerter from 'hooks/useOutsideAlerter';

const showIpaf = process.env.REACT_APP_SHOW_IPAF === "true"

const NewTabList = [
  {
    url: "/",
    name: "Daftar Listing",
    active: DetailListingActive,
    inactive: DetailListingInActive,
    isShow: true
  },
  {
    url: "/ereporting",
    name: "Lapor Transaksi",
    active: EreportingActive,
    inactive: EreportingInActive,
    isShow: true
  },
  {
    url: "/upload-listing",
    name: "Buat Listing",
    active: BuatListingActive,
    inactive: BuatListingInActive,
    isShow: false
  },
  {
    url: "/leads-listing",
    name: "Leads Listing",
    iconType: "png",
    active: LeadsdistributionActive,
    inactive: LeadsdistributionInactive,
    isShow: true
  },
  {
    url: "/key-metric-office-dashboard",
    name: "Key Metric Office Dashboard",
    active: KeyMetricDashboardInActive,
    inactive: KeyMetricDashboardInActive,
    isShow: true
  },
  {
    url: "/marketing",
    name: "Marketing",
    active: MarketingActive,
    inactive: MarketingInActive,
    isShow: true
  },
  {
    url: "/invoice",
    name: "Invoice",
    active: InvoiceActive,
    inactive: InvoiceInActive,
    isShow: true
  },
  {
    url: "/one21-event",
    name: "ONE21 Event",
    iconType: "img",
    active: One21Active,
    inactive: One21Inactive,
    isShow: false
  },
  {
    url: "/ipaf-event",
    name: "IPAF Event",
    iconType: "img",
    active: IpafActive,
    inactive: IpafInactive,
    isShow: showIpaf
  },
  {
    url: "/progress-tracker",
    name: "Progress Tracker",
    iconType: "img",
    active: ProgressTrackerActive,
    inactive: ProgressTrackerInactive,
    isShow: true
  },
  {
    url: "/leads-management",
    name: "Leads Management",
    iconType: "img",
    active: LeadsManagementActive,
    inactive: LeadsManagementInactive,
    isShow: true
  }
]

const NewNavbarTab = ({ list, profile, isHamburgerActive, showInvoice, showInvoiceAndEreporting, listingLeadsCounter }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const [active, setActive] = useState(false);
  const [source, setSource] = useState(list.inactiveIcon);

  useEffect(() => {
    if (location.pathname === list.url) {
      setActive(true)
      setSource(list.activeIcon)
    } else {
      setSource(list.inactiveIcon)
      setActive(false)
    }
  }, [location.pathname]);

  const isLeadsCiputra = list.name == "Leads MyCiputra";
  const isLeadsCiputraPrivate = profile?.company?.id == 228;
  const isInvoice = list.name === "Invoice";
  const isEreporting = list.name === "Lapor Transaksi";
  const isLeadsListing = list.name === "Leads Listing";

  if (!list?.isShow) return null;
  if (isLeadsCiputra && !isLeadsCiputraPrivate) return null;
  if (isInvoice && (!showInvoice || !showInvoiceAndEreporting)) return null;
  if (!showInvoiceAndEreporting && isEreporting) return null;

  if (list.iconType === "png") {
    return (
      <a onClick={() => navigate(list.url)}>
        <div className={`${Styles['wrapper-menu']} ${active && Styles['active']} relative`}>
          <div className={Styles['icon']}>
            {active ? <img src={list.active} alt={list.name} /> : <img src={list.inactive} alt={list.name} />}
          </div>

          {isLeadsListing && listingLeadsCounter && <span className='px-1 py-[10px] flex items-center justify-center h-[20px] w-[32px] bg-red-8 rounded-[6px] border-2 border-solid border-black-0 text-white text-xs font-inter absolute top-[12px] left-[8px]'>{listingLeadsCounter > 99 ? "99+" : listingLeadsCounter}</span>}

          {isHamburgerActive && <p className={Styles['label']}>{list.name}</p>}
        </div>
      </a>
    )
  }
  return (
    <a onClick={() => navigate(list.url)}>
      <div className={`${Styles['wrapper-menu']} ${active && Styles['active']}`}>
        <div className={Styles['icon']}>
          {active ? <list.active /> : <list.inactive />}
        </div>

        {isHamburgerActive && <p className={Styles['label']}>{list.name}</p>}
      </div>
    </a>
  )
}

const NewNavbar = () => {
  const navRef = useRef(null);

  const navigate = useNavigate();
  const { logoutAction, profile, isHamburgerActive, setIsHamburgerActive, showInvoice, showInvoiceAndEreporting, listingLeadsCounter } = useContext(GlobalContext);
  const [openModalLogout, setOpenModalLogout] = useState(false)

  const logoutHandler = () => {
    logoutAction(() => navigate("/login"))
  }

  useOutsideAlerter(navRef, () => setIsHamburgerActive(false))
  return (
    <div
      ref={navRef}
      className={`${Styles['wrapper-new-navbar']} ${isHamburgerActive ? Styles['navbar-active'] : Styles['navbar-inactive']}`}
    >
      <div className={Styles['wrapper-menus']}>
        <div className={Styles['wrapper-content-open']}>
          {isHamburgerActive ? <C21Logo className={Styles['logo']} /> : (
            <div className={Styles['wrapper-c21-logo']}>
              <img src={C21Gold} alt="Century21" className={Styles['c21-logo']} />
            </div>
          )}

          {NewTabList.map((item, index) => (
            <NewNavbarTab listingLeadsCounter={listingLeadsCounter} showInvoiceAndEreporting={showInvoiceAndEreporting} showInvoice={showInvoice} isHamburgerActive={isHamburgerActive} profile={profile} list={item} key={`navbar-${index}`} />
          ))}

          <a>
            <div className={`${Styles['wrapper-menu']} ${Styles['keluar']}`}>
              <div className={Styles['icon']}>
                <NewLogoutIcon onClick={() => setOpenModalLogout(true)} />
              </div>

              {isHamburgerActive && <p className={`${Styles['label']} ${Styles['red']}`}>Keluar</p>}
            </div>
          </a>

        </div>

        <div className={`${isHamburgerActive && Styles['toggler-active']} ${Styles['wrapper-content-close']}`}>
          <DoubleLeftArrow
            onClick={() => setIsHamburgerActive(!isHamburgerActive)}
            className={`${Styles['nav-toggler']} ${Styles[isHamburgerActive ? "toggler-active" : "toggler-inactive"]}`}
          />
        </div>
      </div>

      {openModalLogout ? <ModalConfirmationV1
        noImage={true}
        title="Kamu yakin ingin keluar?"
        confirmText="Oke"
        declineText="Batal"
        confirmAction={() => logoutHandler()}
        declineAction={() => setOpenModalLogout(false)}
        onClose={() => setOpenModalLogout(false)}
      /> : null}
    </div>
  )
}

// export default Navbar
export default NewNavbar
